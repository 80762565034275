<template>
  <v-app-bar color="#19191C" class="elevation-0" dark>
    <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-medium titel">{{ pageTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    </v-app-bar>
</template>


<script>


export default {
    props: [
        'pageTitle'
    ]
}
</script>
<style scoped>
.titel{
  color: #BDBDBD;
  font-weight: 600;
}
</style>
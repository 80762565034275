import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

require('../assets/styles/style.scss');


const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/animation',
    name: 'Animation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Animation.vue')
  },
  {
    path: '/animation1',
    name: 'Animation1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Animation1.vue')
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import(/* webpackChunkName: "about" */ '../views/Overview.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/profile-zoom',
    name: 'ProfileZoom',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileZoom.vue')
  },
  {
    path: '/profile-prediction',
    name: 'ProfilePrediction',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfilePrediction.vue')
  },
  {
    path: '/make-payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/MakePayment.vue')
  },
  {
    path: '/profile-zoom-data',
    name: 'Zoom',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileZoomData.vue')
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import(/* webpackChunkName: "about" */ '../views/Intro.vue')
  },
  {
    path: '/popular-place',
    name: 'popular',
    component: () => import(/* webpackChunkName: "about" */ '../views/PopularPlace.vue')
  },
  {
    path: '/hometown',
    name: 'hometown',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeTown.vue')
  },
  {
    path: '/bedrijven',
    name: 'bedrijven',
    component: () => import(/* webpackChunkName: "about" */ '../views/Bedrijven.vue')
  },
  {
    path: '/happy-hour',
    name: 'happyhour',
    component: () => import(/* webpackChunkName: "about" */ '../views/HappyHour.vue')
  },
  {
    path: '/end',
    name: 'end',
    component: () => import(/* webpackChunkName: "about" */ '../views/End.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <v-app dark>
    <Header class="header" :pageTitle="pageTitle"/>
    <router-view @clickedItem="getClickedItem" @createdTitle="getTitle"  :listdata="items"/>
    <!-- <router-link to="/">Overview</router-link>
    <router-link to="/make-payment">Make Payment</router-link> -->
  </v-app>
</template> 

<script>
import Header from './components/Header'


export default {
  data: () => {
    return {
      items: [ {
                name: 'Baboon Chicken', 
                cat: 'Food',
                price: '10.00',
                time: '10:30',
                logo: require('@/assets/logos/uber.png')  
                },
                {
                name: 'AH', 
                cat: 'Food',
                price: '18.20',
                time: '22:30',
                logo: require('@/assets/logos/ah.png')  
                },
                {
                name: 'Media Markt', 
                cat: 'Elecktronica',
                price: '18.12',
                time: '18:45',
                logo: require('@/assets/logos/mediamarkt.png')   
                },
                {
                name: 'AH', 
                cat: 'Food',
                price: '92.42',
                time: '18:40',
                logo: require('@/assets/logos/ah.png')   
                },
                {
                name: 'AH', 
                cat: 'Food',
                price: '18.12',
                time: '18:25',
                logo: require('@/assets/logos/ah.png')   
                }
              ], 
              pageTitle: 'DataMate'
    }
  },
  components : {
    Header
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },

  methods: {
    async accept() {
      this.showUpgradeUI = false
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    getClickedItem(item) {
      this.items.push(item);
    },
     getTitle(item) {
      console.log('titlele ')
      this.pageTitle = item;
    }
  }
}
</script>

<style lang="scss">
.header{
  max-height: 56px;
}
@media (min-width:1025px) {
.v-application--wrap {
    background: #19191C;
    max-width: 420px !important;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}

body{
  padding: 40px;
    max-height: 100vh;
    overflow: scroll;
    background: #121212;
    overflow-x: hidden;
}
div#app {
    max-height: fit-content;
}
}

.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100% !important;
}

.container{
  background: #19191C;
}

</style>
